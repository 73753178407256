import React from 'react';
import { useShoppingCart } from 'use-shopping-cart';
import { formatCurrencyString } from 'use-shopping-cart/src/util';
import { FormattedMessage } from 'gatsby-plugin-intl';
import products from '../../../functions/create-checkout/data/products.json';

const ProductDetails = () => {
  const { addItem } = useShoppingCart();
  // TO DO: Change it to some reasonable JavaScript
  let productPath;
  if (typeof window !== 'undefined') {
    productPath = window.location.pathname.slice(6);
  }
  const filteredProduct = products.filter((product) => product.sku === productPath);
  return (
    filteredProduct.map((product) => (
      <article key={product.sku} className="product-details">
        <figure className="product-details-img">
          <img src={product.image} alt={product.name} className="card-img" />
        </figure>
        <div className="card-details-container">
          <div>
            <div className="details-header">
              <h3>{product.name}</h3>
              <h3>
                {formatCurrencyString({
                  value: product.price,
                  currency: product.currency,
                })}
              </h3>
            </div>
            <p className="description">{product.description}</p>
          </div>
          <div>
            <p className="subtitle description"><FormattedMessage id="shop.specification" /></p>
            <p className="description">13"x19" print on acrylic mat paper. </p>
          </div>
          <div>
            <p className="subtitle description"><FormattedMessage id="shop.shipping" /></p>
            <p className="description"><FormattedMessage id="shop.shippingCalculated" /></p>
          </div>
          <button
            aria-label="add to cart"
            type="button"
            className="btn btn-primary"
            onClick={() => addItem(product)}
          >
            Add To Cart
          </button>
        </div>
      </article>
    ))
  );
};

export default ProductDetails;
