import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../layouts/main/Layout';
import ProductDetails from '../components/ProductDetails/ProductDetails';


const ProductPost = ({ pageContext: { locale }, data }) => {
  const { markdownRemark: product } = data;
  return (
    <Layout locale={locale}>
      <div dangerouslySetInnerHTML={{ __html: product.html }} />
      <ProductDetails />
    </Layout>
  );
};

ProductPost.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
};

export default ProductPost;

export const pageQuery = graphql`
  query ProductPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        sku
        path
      }
    }
  }
`;
